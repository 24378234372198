<p class="terms-of-use__date">Updated on {{ UPDATE_DATE }}</p>
<div class="terms-of-use__text" #termsOfUseText>
  <h4 *ngIf="showMainTitle">Terms of Use</h4>
  <p>
    These Terms of Use are our rules if you want to use your account or other services that can be found on
    <a [href]="termsOfUseUrl" target="_blank"> {{ termsOfUseUrl }}</a
    >.
  </p>
  <p>These rules apply to all users and visitors as of when you register to create an account and use the Applications on your account.</p>

  <h4>Who we are and how to contact us</h4>

  <p>
    In the UK, Kaplan International Pathways is the trading name of Kaplan International Colleges UK Ltd. Company No. 05268303. Registered in England.
    Registered office: Palace House, 3 Cathedral Street, London SE1 9DE, United Kingdom.
  </p>

  <p>By using your account, you confirm that you approve of these rules and agree to comply with them.</p>

  <p>If you do not agree to these rules, you will not be able to log in to your account.</p>

  <p>
    We recommend that you print a copy of these rules so you can reference them in the future if you need to. If there is anything under these rules that you do
    not understand, we suggest you let Admission or College know and they may be able to assist, or you can contact us at
    <a [href]="'mailto:' + supportEmail">{{ supportEmail }}</a>
  </p>

  <h4>There are other policies that may apply to you</h4>

  <ul>
    <li>
      <a [href]="privacyUrl" rel="noreferrer noopener" target="_blank">Privacy Policy</a>, which explains how we handle your personal information, what
      information we hold, where we get it from, what we use it for and who we share it with.
    </li>
    <li>
      <a [href]="privacyUrl" rel="noreferrer noopener" target="_blank">Cookie Policy</a>, which sets out information about the cookies on your account. These
      are little bits of computer software that give us Information about your visit to our website, like which pages you visited, where you click, any searches
      you made on the site and other sites that you go to from our site.
    </li>
  </ul>

  <h4>License</h4>
  <p>We own, or have a license to use, any logos, trademarks, images, videos, designs, content, processes or systems displayed on our website.</p>
  <p>You must not:</p>
  <ul>
    <li>Copy or try to re-sell any part of our student portal</li>
    <li>Infringe any copyright, database right or trademark of any other person</li>
  </ul>

  <h4>We may make changes</h4>
  <p>We may amend these rules from time to time.</p>

  <h4>Our rules</h4>

  <ol>
    <li>Do not pretend to be someone else when logging into your account.</li>

    <li>
      Don't upload or send any type of virus or do anything that might interfere with the way your account works, its software, network or equipment or damage
      it in any way.
    </li>

    <li>Don't copy or try to re-sell any part of your account.</li>

    <li>Don't let anyone else use your log in details.</li>

    <li>Keep your password secret.</li>

    <li>Let us know immediately if you find out someone else is using your log in details or password.</li>
  </ol>

  <h4>Breaking the rules</h4>

  <p>If we decide that you have broken these rules, we will decide what will happen next.</p>

  <h4>Breaking the rules may mean we carry out any of the following:</h4>
  <ol start="7">
    <li>Stop you being able to use your account permanently or temporarily.</li>

    <li>Give you a warning.</li>

    <li>Take other action that we think is reasonable and appropriate.</li>

    <li>Take legal action against you or let the police or other authorities know if we think that is reasonable, or the law compels us to do this.</li>
  </ol>
  <p>
    If these Terms of Use are breached, we can decide how to respond, and we are not responsible for the impact our decisions may have on your use of your
    account.
  </p>

  <h4>We may suspend or withdraw your account</h4>

  <p>We do not guarantee that your account, or any content on it, will always be available.</p>

  <p>We may suspend, withdraw or restrict the availability of all or any part of your account, if we need to for business and operational reasons.</p>

  <p>We will always try to give you reasonable notice of any suspension or withdrawal.</p>

  <h4>How to contact us</h4>
  <p>
    If you have any questions regarding these Terms of Use, please contact us on
    <a [href]="'mailto:' + supportEmail">{{ supportEmail }}</a>
  </p>
</div>
<p *ngIf="showDisclaimer">
  <svg class="warning-icon" width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M12.0015 4.62891H11.9985L3.48657 20.9978L3.488 21.0004H20.5121L20.5135 20.9978L12.0015 4.62891ZM11.1563 9.00036H12.8438V15.7504H11.1563V9.00036ZM12 19.5004C11.7775 19.5004 11.56 19.4344 11.375 19.3108C11.19 19.1871 11.0458 19.0114 10.9607 18.8059C10.8755 18.6003 10.8533 18.3741 10.8967 18.1559C10.9401 17.9377 11.0472 17.7372 11.2046 17.5799C11.3619 17.4225 11.5623 17.3154 11.7806 17.272C11.9988 17.2286 12.225 17.2508 12.4306 17.336C12.6361 17.4211 12.8118 17.5653 12.9354 17.7503C13.0591 17.9353 13.125 18.1529 13.125 18.3754C13.125 18.6737 13.0065 18.9599 12.7955 19.1709C12.5846 19.3818 12.2984 19.5004 12 19.5004Z"
      fill="#C18A24"
    />
    <path
      d="M21.75 22.5H2.25001C2.12115 22.5 1.99447 22.4668 1.88218 22.4036C1.76988 22.3404 1.67576 22.2494 1.60889 22.1392C1.54203 22.0291 1.50466 21.9036 1.50041 21.7748C1.49615 21.646 1.52515 21.5183 1.58461 21.404L11.3346 2.65396C11.3979 2.53219 11.4935 2.43013 11.6108 2.3589C11.7281 2.28767 11.8627 2.25 12 2.25C12.1373 2.25 12.2719 2.28767 12.3892 2.3589C12.5065 2.43013 12.6021 2.53219 12.6654 2.65396L22.4154 21.404C22.4749 21.5183 22.5039 21.646 22.4996 21.7748C22.4953 21.9036 22.458 22.0291 22.3911 22.1392C22.3242 22.2494 22.2301 22.3404 22.1178 22.4036C22.0055 22.4668 21.8789 22.5 21.75 22.5ZM3.48803 21H20.512L20.5135 20.9975L12.0015 4.62856H11.9985L3.48653 20.9975L3.48803 21Z"
      fill="#C18A24"
    />
  </svg>
  You will need to accept our terms of use in order to access your account.
</p>
