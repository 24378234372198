import { APP_BASE_HREF, CommonModule, PlatformLocation } from '@angular/common';
import { HTTP_INTERCEPTORS, HttpClientModule, HttpErrorResponse } from '@angular/common/http';
import { ErrorHandler, NgModule, NgZone } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { Router, RouterModule } from '@angular/router';
import { MSAL_GUARD_CONFIG, MSAL_INSTANCE, MsalBroadcastService, MsalGuard, MsalRedirectComponent, MsalService } from '@azure/msal-angular';
import { COMPANY } from '@klg/shared/tokens';
import { COMPANIES } from '@klg/shared/types';
import { getBaseHref } from '@klg/shared/utils';
import { HttpErrorInterceptor, msalGuardConfig, MSALInstanceFactory } from '@pw/account/auth';
import { PathwaysAccountUiModule } from '@pw/account/ui';
import { PATHWAYS_CREDENTIAL_BASE_PATH } from '@pw/api/credential';
import { PATHWAYS_EMAIL_BASE_PATH } from '@pw/api/email';
import { PATHWAYS_PROFILE_BASE_PATH } from '@pw/api/profile';
import { PATHWAYS_VERIFICATION_BASE_PATH } from '@pw/api/verification';
import { StudentProfileService } from '@pw/profile-data-access';
import { environment, PATHWAYS_ENVIRONMENT, PathwaysAccountRoutes } from '@pw/shared/environment';
import { PathwaysUiModule } from '@pw/shared/ui';
import { PathwaysTermsOfUseModule } from '@pw/terms-of-use';
import { GoogleTagManagerModule } from 'angular-google-tag-manager';
import { MessageService } from 'primeng/api';
import { AvatarModule as PrimeNgAvatarModule } from 'primeng/avatar';
import { ConfirmDialogModule as PrimeNgConfirmDialogModule } from 'primeng/confirmdialog';
import { AppComponent } from './app.component';
import { pathwaysAccountAppRoutes } from './app.routes';
import { AccountMenuComponent } from './components/account-menu/account-menu.component';
import { BasePageComponent } from './components/base-page/base-page.component';
import { AccountErrorPageComponent } from './error-page/error-page.component';
import { LocalSignOutPageComponent } from './local-sign-out/local-sign-out.component';
import { PasswordChangedComponent } from './password-changed/password-changed.component';
import { SignInComponent } from './sign-in/sign-in.component';
import { SignOutPageComponent } from './sign-out/sign-out.component';
import { TermsOfUsePageComponent } from './terms-of-use-page/terms-of-use-page.component';
import { PATHWAYS_NOTIFICATION_BASE_PATH } from '@pw/api/notification';
import { PhoneNumberChangedComponent } from './phone-number-changed/phone-number-changed.component';

class PathwaysAccountErrorHandler implements ErrorHandler {
  constructor(private readonly router: Router, private readonly ngZone: NgZone) {}

  handleError(error: Error): void {
    console.error(error);

    // Do not redirect to error page on certain cases
    if (
      error.message.includes('ExpressionChangedAfterItHasBeenCheckedError') ||
      error.message.includes('AADB2C90091') // access_denied: AADB2C90091: The user has cancelled entering self-asserted information.
    ) {
      return;
    }

    const navigationCommands = ['/', PathwaysAccountRoutes.ERROR];
    try {
      const matchErrorMessage = error.message.match(/HttpErrorResponse: ({.*})/);
      if (matchErrorMessage && matchErrorMessage[1]) {
        const httpError = JSON.parse(matchErrorMessage[1]) as HttpErrorResponse;
        if (httpError.status) {
          navigationCommands.push(httpError.status.toString());
        }
      }
    } catch (parseError) {
      console.error('Error parsing error message', parseError);
    }
    this.ngZone.run(() => this.router.navigate(navigationCommands, { skipLocationChange: true }));
  }
}

@NgModule({
  declarations: [
    AppComponent,
    AccountMenuComponent,
    BasePageComponent,
    SignOutPageComponent,
    LocalSignOutPageComponent,
    PasswordChangedComponent,
    AccountErrorPageComponent,
    SignInComponent,
    TermsOfUsePageComponent,
    PhoneNumberChangedComponent,
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    CommonModule,
    RouterModule.forRoot(pathwaysAccountAppRoutes),
    PathwaysUiModule,
    PathwaysAccountUiModule,
    PathwaysTermsOfUseModule,
    PrimeNgAvatarModule,
    PrimeNgConfirmDialogModule,
    HttpClientModule,
    GoogleTagManagerModule.forRoot({
      id: environment.GOOGLE_TAG_MANAGER.ID,
      gtm_auth: environment.GOOGLE_TAG_MANAGER.AUTH,
      gtm_preview: environment.GOOGLE_TAG_MANAGER.ENV,
    }),
  ],
  providers: [
    { provide: APP_BASE_HREF, useFactory: getBaseHref, deps: [PlatformLocation] },
    { provide: COMPANY, useValue: COMPANIES.PATHWAYS },
    { provide: PATHWAYS_ENVIRONMENT, useValue: environment },
    { provide: PATHWAYS_EMAIL_BASE_PATH, useValue: environment.API_BASE_URL },
    { provide: PATHWAYS_CREDENTIAL_BASE_PATH, useValue: environment.API_BASE_URL },
    { provide: PATHWAYS_PROFILE_BASE_PATH, useValue: environment.API_BASE_URL },
    { provide: PATHWAYS_NOTIFICATION_BASE_PATH, useValue: environment.API_BASE_URL },
    { provide: PATHWAYS_VERIFICATION_BASE_PATH, useValue: environment.API_BASE_URL },
    { provide: HTTP_INTERCEPTORS, useClass: HttpErrorInterceptor, multi: true },
    { provide: ErrorHandler, useClass: PathwaysAccountErrorHandler, deps: [Router, NgZone] },
    { provide: MSAL_INSTANCE, useFactory: MSALInstanceFactory, deps: [APP_BASE_HREF] },
    { provide: MSAL_GUARD_CONFIG, useValue: msalGuardConfig },
    { provide: 'error404Resolver', useValue: () => '404' },
    MsalService,
    MessageService,
    MsalGuard,
    MsalBroadcastService,
    StudentProfileService,
  ],
  bootstrap: [AppComponent, MsalRedirectComponent],
})
export class AppModule {}
