import { Component, OnInit } from '@angular/core';
import { PathwaysAuthService } from '@pw/account/auth';

@Component({
  template: '',
})
export class SignInComponent implements OnInit {
  constructor(private readonly authService: PathwaysAuthService) {}

  ngOnInit(): void {
    this.authService.login();
  }
}
