import { DOCUMENT } from '@angular/common';
import { inject, Injectable } from '@angular/core';
import { observeElementAddedToDom, observeMutation } from '@klg/shared/utils';
import { environment } from '@pw/shared/environment';
import { map, Observable, of, Subscription } from 'rxjs';
import { switchMap, take } from 'rxjs/operators';
import { getBaseCivicCookiesConfig } from '../config';
import { setSlidersOnOff } from '../functions';
import { CivicConfig, CivicCookieControl } from '../types';
// eslint-disable-next-line no-var
declare var CookieControl: CivicCookieControl;

@Injectable({
  providedIn: 'root',
})
export class CivicCookiesService {
  protected readonly document = inject(DOCUMENT);

  private subscriptions = new Subscription();

  public disconnect(): void {
    this.subscriptions.unsubscribe();
  }

  public load() {
    if (this.checkCookieControl() && !environment.MAINTENANCE_MODE) {
      CookieControl.load(this.getCookieConfig());

      const cccPresent$: Observable<boolean> = this.isCookieControlLoaded()
        ? of(true)
        : observeElementAddedToDom('#ccc').pipe(
            take(1),
            map(() => true),
          );

      this.subscriptions.add(
        cccPresent$
          .pipe(switchMap(() => observeMutation(this.getCookieControlElement(), { subtree: true, childList: true })))
          .subscribe(() => setSlidersOnOff()),
      );
    }
  }

  public open(): void {
    if (this.checkCookieControl()) {
      if (!this.isCookieControlLoaded()) {
        this.load();
      }
      CookieControl.open();
    }
  }

  protected checkCookieControl(): boolean {
    try {
      if (!CookieControl) {
        console.warn('The cookie control script must be loaded.');
        return false;
      }
    } catch {
      console.warn('The cookie control script must be loaded.');
      return false;
    }

    return true;
  }

  protected deleteOptionalCookiesIfRevoked(): void {
    if (this.checkCookieControl()) {
      CookieControl.config().optionalCookies.forEach((categoryConfig, categoryIndex) => {
        if (CookieControl.getCategoryConsent(categoryIndex) === false) {
          categoryConfig.cookies?.forEach((cookieName) => CookieControl.delete(cookieName));
        }
      });
    }
  }

  protected getCookieConfig(): CivicConfig {
    return getBaseCivicCookiesConfig(environment.PRIVACY_URL);
  }

  protected getCookieControlElement(): HTMLElement {
    return this.document.getElementById('ccc');
  }

  protected getOptionalCookiesConsent(categoryName: string): boolean {
    if (this.checkCookieControl()) {
      const categoryIndex = CookieControl.config().optionalCookies.findIndex((categoryConfig) => categoryConfig.name === categoryName);
      return CookieControl.getCategoryConsent(categoryIndex);
    }
  }

  protected isCookieControlLoaded(): boolean {
    return !!this.getCookieControlElement();
  }
}
