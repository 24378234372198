/**
 * Profile API
 * Profile
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

export interface FeatureItemApiDto {
  id: FeatureItemApiDto.IdApiDtoEnum;
  enabled: boolean;
}
export namespace FeatureItemApiDto {
  export type IdApiDtoEnum = 'attendance';
  export const IdApiDtoEnum = {
    attendance: 'attendance' as IdApiDtoEnum,
  };
}
