<pw-public-page
  [logoSrc]="logoSrc"
  [mobileLogoSrc]="mobileLogoSrc"
  [sideImageSrc]="sideImageSrc"
  [pageTitle]="pageTitle"
  [mobileImageSrc]="mobileImageSrc"
  [mobilePageTitle]="mobilePageTitle"
  [descriptions]="descriptions"
  [hideDescriptions]="hideDescriptions"
  [wideContent]="wideContent"
  [stickyFooter]="stickyFooter"
  [template]="template"
>
  <ng-content></ng-content>
  <ng-content alternative select="[alternative]"></ng-content>
  <pw-account-footer-links footer class="account-public-page__footer"></pw-account-footer-links>
</pw-public-page>
