import { Injectable } from '@angular/core';
import { Resolve, Router } from '@angular/router';
import { PathwaysAuthService } from '@pw/account/auth';
import { PathwaysEmailService } from '@pw/email-data-access';
import { Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class SignOutResolver implements Resolve<boolean> {
  constructor(private readonly authService: PathwaysAuthService, private readonly emailService: PathwaysEmailService, private readonly router: Router) {}

  resolve(): Observable<boolean> {
    if (!this.authService.getActiveAccount()) {
      return of(false);
    }

    return this.authService.logout().pipe(map(() => true));
  }
}
