import { Component, inject, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { PathwaysAuthService, User } from '@pw/account/auth';
import { StudentFeaturesEnum } from '@pw/profile-data-access';
import { CivicCookiesWithOptionalsService } from '@pw/shared/cookies';
import { environment, PATHWAYS_ENVIRONMENT, PathwaysAccountRoutes, PathwaysEnvironment } from '@pw/shared/environment';
import { PathwaysGoogleTagManagerService } from '@pw/shared/google-tag-manager';
import { GtmEventNames, SignOutType } from '@pw/shared/types';
import { ConfirmationService, MenuItem } from 'primeng/api';

@Component({
  selector: 'pw-account-menu',
  templateUrl: './account-menu.component.html',
  styleUrls: ['./account-menu.component.scss'],
  providers: [ConfirmationService],
})
export class AccountMenuComponent implements OnInit {
  public currentUser: User = new User({});
  public userMenuItems: MenuItem[];

  private readonly environment = inject<PathwaysEnvironment>(PATHWAYS_ENVIRONMENT);
  private readonly router = inject(Router);
  private readonly authService = inject(PathwaysAuthService);
  private readonly confirmationService = inject(ConfirmationService);
  private readonly activatedRoute = inject(ActivatedRoute);
  private readonly gtmService = inject(PathwaysGoogleTagManagerService);
  private readonly cookiesService = inject(CivicCookiesWithOptionalsService);

  public ngOnInit(): void {
    this.currentUser = this.activatedRoute.snapshot?.data?.currentUser;

    this.userMenuItems = [
      {
        id: 'manage-account-link',
        label: 'Manage account',
        command: () => this.router.navigate(['/', PathwaysAccountRoutes.MANAGE_ACCOUNT]),
      },
      { separator: true },
      {
        id: 'cookie-settings-link',
        label: 'Cookie settings',
        command: () => this.cookiesService.open(),
      },
      {
        id: 'terms-of-use-link',
        label: 'Terms of use',
        icon: 'external-link',
        command: () => {
          const url = `${this.environment.PORTAL_PATH}/${PathwaysAccountRoutes.TERMS_OF_USE}`;
          window.open(url, '_blank');
        },
      },
      {
        id: 'privacy-policy-link',
        label: 'Privacy and cookie policy',
        icon: 'external-link',
        command: () => window.open(this.environment.PRIVACY_URL, '_blank'),
      },
      { separator: true },
      {
        id: 'sign-out-link',
        label: 'Sign out',

        command: () =>
          this.confirmationService.confirm({
            message: `
            <p>Signing out will close down all applications currently being used.</p>
            <p>Please make sure you have saved everything before you sign out.</p>
            `,
            accept: () => this.authService.logout(SignOutType.DEFAULT),
            closeOnEscape: true,
            dismissableMask: true,
          }),
      },
    ];

    const attendanceFeature = this.currentUser.features.find((feature) => feature.id === StudentFeaturesEnum.attendance);
    const showAttendanceMenuItem = environment.FEATURE_FLAGS.ATTENDANCE_OVERVIEW_ENABLED && attendanceFeature?.enabled;

    if (showAttendanceMenuItem) {
      this.userMenuItems.splice(1, 0, {
        id: 'attendance-overview-link',
        label: 'Attendance',
        command: () => {
          this.router.navigate(['/', PathwaysAccountRoutes.ATTENDANCE_OVERVIEW]);
          this.gtmService.pushTag({ event: GtmEventNames.HOME_ATTENDANCE_MENU_OPTION });
        },
      });
    }
  }
}
