import jwt_decode from 'jwt-decode';
import { Component, OnInit } from '@angular/core';
import { PathwaysAuthService } from '@pw/account/auth';
import { environment, PathwaysAccountRouteParams, PathwaysAccountRoutes } from '@pw/shared/environment';
import { CommonModule } from '@angular/common';
import { PathwaysAccountUiModule } from '@pw/account/ui';
import { PathwaysUiModule, PwButtonComponent } from '@pw/shared/ui';
import { ActivatedRoute, Router } from '@angular/router';
import { catchError, finalize } from 'rxjs/operators';
import { TokenValidationService } from '@pw/verification-data-access';
import { GtmEventNames, Size } from '@pw/shared/types';
import { HttpErrorResponse } from '@angular/common/http';
import { of, tap } from 'rxjs';

@Component({
  standalone: true,
  imports: [CommonModule, PathwaysAccountUiModule, PwButtonComponent, PathwaysUiModule],
  selector: 'pw-confirm-account-reset',
  templateUrl: './confirm-account-reset.component.html',
  styleUrls: ['./confirm-account-reset.component.scss'],
})
export class ConfirmAccountResetComponent implements OnInit {
  readonly gtmEventNames = GtmEventNames;
  readonly supportEmail = environment.SUPPORT_EMAIL;
  readonly buttonSize = Size;
  descriptions: string[] = [
    'If you reset your account, you will need to sign up for a new one. If the reset link expires, you will need to sign in and request to reset your account again.',
  ];
  pageTitle = 'Reset Account';
  pageIllustration = 'assets/illustrations/celebration.png';
  apiErrorOccurred = false;
  apiRequestInProgress = false;
  pageIllustrationMobile = '';
  errorDescription = '';

  private verificationId = '';
  private verificationToken = '';

  constructor(
    private readonly activatedRoute: ActivatedRoute,
    private readonly authService: PathwaysAuthService,
    private readonly router: Router,
    private readonly tokenValidationService: TokenValidationService,
  ) {}

  ngOnInit(): void {
    this.verificationId = this.activatedRoute.snapshot?.paramMap.get(PathwaysAccountRouteParams.ID);
    this.verificationToken = this.activatedRoute.snapshot?.paramMap.get(PathwaysAccountRouteParams.TOKEN);
  }

  resetAccount() {
    this.apiRequestInProgress = true;
    this.tokenValidationService
      .checkToken(this.verificationId, this.verificationToken)
      .pipe(
        tap(() => this.goToSignUp()),
        catchError((apiRes: unknown) => {
          this.apiErrorOccurred = true;
          const apiErrorRes = apiRes as unknown as HttpErrorResponse;
          switch (apiErrorRes.status) {
            case 400:
            case 404:
              this.pageTitle = $localize`Link expired`;
              this.errorDescription = $localize`The link you are trying to access is no longer available.`;
              break;
            default:
              this.pageTitle = $localize`Server error`;
              this.errorDescription = $localize`We are having a problem with our system, please try again later.`;
          }

          this.pageIllustration = 'assets/illustrations/student-meets-problem.png';
          this.pageIllustrationMobile = 'assets/illustrations/student-meets-problem.mobile.png';
          this.descriptions = [];

          return of();
        }),
        finalize(() => {
          this.apiRequestInProgress = false;
        }),
      )
      .subscribe();
  }

  goToSignIn(): void {
    this.authService.login();
  }

  private goToSignUp(): void {
    const decodedToken = jwt_decode<Record<string, string>>(this.verificationToken);
    const [birthYear, birthMonth, birthDay] = decodedToken.dateOfBirth ? decodedToken.dateOfBirth.split('-') : [];

    this.router.navigate([`/${PathwaysAccountRoutes.SIGN_UP}`], {
      queryParams: {
        birthDay,
        birthMonth,
        birthYear,
        kssId: decodedToken.kssId,
      },
    });
  }
}
