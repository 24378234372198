import { Injectable } from '@angular/core';
import { PathwaysAuthService } from '@pw/account/auth';
import { EmailApiService, InlineResponse200ApiDto, SendChangedPasswordEmailRequestApiDto } from '@pw/api/email';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class PathwaysEmailService {
  constructor(private emailService: EmailApiService, private authService: PathwaysAuthService) {}

  public sendPasswordChangedEmail(emailPayload: SendChangedPasswordEmailRequestApiDto): Observable<InlineResponse200ApiDto> {
    return this.emailService.sendPasswordChangedEmail(this.authService.getBearerToken(), emailPayload);
  }
}
