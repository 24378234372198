export function getLoadingOverlay(): HTMLDivElement {
  return document.getElementById('loading-overlay') as HTMLDivElement;
}

export function hideLoadingOverlay() {
  getLoadingOverlay()?.classList.add('hidden');

  // Change loading message to default
  getLoadingOverlay().querySelector<HTMLDivElement>('.loading__content__text__value--default').style.display = 'block';
  getLoadingOverlay().querySelector<HTMLDivElement>('.loading__content__text__value--long').style.display = 'none';
}

export function showLoadingOverlay() {
  getLoadingOverlay()?.classList.remove('hidden');
}
