import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve, Router } from '@angular/router';
import { PathwaysAuthService } from '@pw/account/auth';
import { PathwaysEmailService } from '@pw/email-data-access';
import { PathwaysAccountRoutes } from '@pw/shared/environment';
import { Observable, of } from 'rxjs';
import { map, mergeMap } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class SendPasswordChangeEmailResolver implements Resolve<boolean> {
  constructor(private readonly authService: PathwaysAuthService, private readonly emailService: PathwaysEmailService, private readonly router: Router) {}

  resolve(route: ActivatedRouteSnapshot): Observable<boolean> {
    const masterProfileId = route.parent.data?.currentUser?.masterProfileId;

    if (masterProfileId) {
      return this.emailService.sendPasswordChangedEmail({ masterProfileId }).pipe(
        mergeMap(() => this.authService.logout(PathwaysAccountRoutes.PASSWORD_CHANGED)),
        map(() => true),
      );
    } else {
      of(false);
    }
  }
}
