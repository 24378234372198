import { Component } from '@angular/core';

@Component({
  templateUrl: './terms-of-use-page.component.html',
  styleUrls: ['./terms-of-use-page.component.scss'],
})
export class TermsOfUsePageComponent {
  logoAlt = 'Kaplan International Pathways';
  logoSrc = 'assets/logos/kaplan-pathways-blue-logo.svg';
}
