import { setSlidersOnOff } from '../functions';
import { CivicConfig } from '../types';

const AZURE_B2C_COOKIES = ['x-ms-cpim-*'];
const CIVIC_COOKIES = ['CookieControl'];
const GOOGLE_ANALYTICS_COOKIES = ['_ga', '_gid', '_ga_*', '_gac_gb_*'];
const MICROSOFT_CLARITY_COOKIES = ['_clsk', '_clck', 'CLID', 'ANONCHK', 'MR', 'MUID', 'SM'];

export const getBaseCivicCookiesConfig = (privacyUrl: string): CivicConfig => ({
  apiKey: '188a6716f36efe5e49b026ee528a7f0650e58e56',
  product: 'PRO_MULTISITE',
  layout: 'SLIDEOUT',
  position: 'LEFT',
  theme: 'LIGHT',
  subDomains: true,
  rejectButton: false,
  sameSiteCookie: true,
  sameSiteValue: 'Strict',
  setInnerHTML: true,
  settingsStyle: 'link',
  toggleType: 'slider',
  closeStyle: 'icon',
  notifyDismissButton: false,
  initialState: 'NOTIFY',
  mode: 'GDPR',
  acceptBehaviour: 'recommended',
  branding: {
    fontFamily: `'Open Sans', Arial, Helvetica, sans-serif`,
    fontSizeTitle: '20px',
    fontSizeHeaders: '18px',
    fontSize: '16px',
    fontColor: '#222121',
    notifyFontColor: '#222121',
    notifyBackgroundColor: '#ffffff',
    acceptBackground: '#005de8',
    acceptText: '#ffffff',
    toggleText: '#222121',
    toggleColor: '#a0a0a0',
    toggleBackground: '#ffffff',
    removeIcon: true,
    removeAbout: true,
  },
  excludedCountries: [],
  statement: {
    description: 'For more detailed information on the cookies we use, please check our',
    name: 'Cookie & privacy policy',
    url: privacyUrl,
    updated: '01/08/2021',
  },
  text: {
    title: 'This site uses cookies to store information on your computer',
    intro: `Some of these cookies are essential to make our site work and others help us to improve by giving us some insight into how the site is being used.`,
    acceptRecommended: 'Accept recommended settings',
    acceptSettings: 'Accept all',
    rejectSettings: 'I Do Not Accept',
    necessaryTitle: 'Necessary cookies',
    necessaryDescription: `These cookies are necessary for the website to function and cannot be switched off in our systems.
      If you have a blocker plugin set-up on your browser, some parts of our website may not be displayed.
      These cookies do not store any personally identifiable information.`,
    thirdPartyTitle: 'Warning: Some cookies require your attention.',
    thirdPartyDescription:
      `<p>Consent for the some 3rd party cookies could not be automatically revoked. Please follow the link(s) below to opt out manually.` +
      `<ul>` +
      `<li>Google Chrome:&nbsp;<a href="https://www.google.com/chrome/browser/privacy/">https://www.google.com/chrome/browser/privacy/</a></p></li>` +
      `<li>Google Analytics:&nbsp;<a href="https://tools.google.com/dlpage/gaoptout">https://tools.google.com/dlpage/gaoptout</a></li>` +
      `<li>Mozilla Firefox:&nbsp;<a href="http://www.mozilla.org/en-US/privacy">www.mozilla.org/en-US/privacy</a></li>` +
      `<li>To opt-out of IDE, please visit:&nbsp;<a href="https://policies.google.com/technologies/managing">https://policies.google.com/technologies/managing</a></li>` +
      `<li>Google:&nbsp;<a href="https://www.google.com/policies/technologies/types/">https://www.google.com/policies/technologies/types/</a></li>` +
      `<li>Facebook and Instagram:</strong>&nbsp;<a href="https://www.facebook.com/about/privacyshield">https://www.facebook.com/about/privacyshield</a></li>` +
      `</ul>`,
    on: 'On',
    off: 'Off',
    notifyTitle: 'Your choice regarding cookies on this site',
    notifyDescription: 'We use cookies to optimise site functionality and give you the best possible experience. Click “Cookie settings” for more information.',
    accept: 'I Accept',
    reject: 'I Reject',
    settings: 'Cookie settings',
    closeLabel: 'Close',
    cornerButton: 'Set cookie settings.',
    landmark: 'Cookie preferences.',
    showVendors: 'Show vendors within this category',
    thirdPartyCookies: 'This vendor may set third party cookies.',
    readMore: 'Read more',
  },
  necessaryCookies: [...CIVIC_COOKIES, ...AZURE_B2C_COOKIES],
  optionalCookies: [
    {
      name: 'performance',
      label: 'Performance cookies',
      description:
        `Please use the toggle to select and save your cookie preferences. ` +
        `Performance cookies, or also known as analytical cookies, collect information about your use of this site and enable us to improve the way it works.`,
      cookies: [...GOOGLE_ANALYTICS_COOKIES, ...MICROSOFT_CLARITY_COOKIES],
      lawfulBasis: 'consent',
      recommendedState: true,
      onAccept: () => {
        setSlidersOnOff();
      },
      onRevoke: () => {
        setSlidersOnOff();
      },
    },
  ],
});
