<pw-menu [menuItems]="userMenuItems">
  <p-avatar [label]="currentUser.initials" shape="circle"></p-avatar>
</pw-menu>

<p-confirmDialog #cd>
  <ng-template pTemplate="header">
    <h6 i18n>Sign out of student portal?</h6>
  </ng-template>
  <ng-template pTemplate="footer">
    <div class="account-menu__buttons">
      <pw-button label="Cancel" [secondary]="true" (buttonClicked)="cd.reject()" i18n-label></pw-button>
      <pw-button label="Sign out" (buttonClicked)="cd.accept()" i18n-label></pw-button>
    </div>
  </ng-template>
</p-confirmDialog>
