import { InlineResponse202ApiDto } from '@pw/api/verification';

export enum VerificationTypes {
  REGISTRATION_EMAIL = 'registration_email',
  UPDATED_REGISTRATION_EMAIL = 'updated_registration_email',
  RESET_QR_CODE_EMAIL = 'reset_qr_code_email',
  RESET_ACCOUNT_EMAIL = 'reset_account_email',
}

export type InlineResponse202 = InlineResponse202ApiDto;
