<pw-account-public-page
  sideImageSrc="assets/illustrations/celebration.png"
  mobileImageSrc="assets/illustrations/celebration.mobile.png"
  i18m-pageTitle
  pageTitle="Change password"
>
  <div>
    <p i18n>Your password has been successfully changed.</p>
    <p>Please <a (click)="goToSignIn()">sign into your account</a></p>
  </div>
</pw-account-public-page>
