import { Component, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PathwaysUiModule } from '../../pathways-shared-ui.module';
import { hideLoadingOverlay } from '../../functions/loading-overlay.functions';

@Component({
  standalone: true,
  selector: 'pw-maintenance-mode-page',
  templateUrl: './maintenance-mode-page.component.html',
  imports: [CommonModule, PathwaysUiModule],
  styleUrls: ['./maintenance-mode-page.component.scss'],
})
export class MaintenanceModePageComponent implements OnInit {
  logoSrc = `assets/logos/kaplan-pathways-white-logo.svg`;
  illustrationSrc = `assets/illustrations/maintenance.png`;
  pageTitle = 'We’re down for maintenance';

  ngOnInit() {
    hideLoadingOverlay();
  }
}
