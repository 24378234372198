<header class="tou__header">
  <img *ngIf="logoSrc" class="tou__logo" [src]="logoSrc" [attr.alt]="logoAlt" />
</header>
<main class="tou__main">
  <div class="tou__main-container">
    <h1>Terms of use</h1>
    <pw-terms-of-use [showDisclaimer]="false"></pw-terms-of-use>
  </div>
</main>
<footer class="tou__footer">
  <pw-copyright class="tou__footer-left"></pw-copyright>
  <pw-account-footer-links class="tou__footer-main"></pw-account-footer-links>
</footer>
