import { Component, Input } from '@angular/core';

@Component({
  selector: 'pw-account-public-page',
  templateUrl: './public-page.component.html',
  styleUrls: ['./public-page.component.scss'],
})
export class AccountPublicPageComponent {
  @Input() logoSrc = 'assets/logos/kaplan-pathways-white-logo.svg';
  @Input() mobileLogoSrc = 'assets/logos/kaplan-pathways-blue-logo.svg';
  @Input() sideImageSrc = '';
  @Input() pageTitle = '';
  @Input() mobileImageSrc = '';
  @Input() mobilePageTitle = '';
  @Input() descriptions: string[] = [];
  @Input() hideDescriptions = false;
  @Input() wideContent = false;
  @Input() stickyFooter = true;
  @Input() template = '';
}
