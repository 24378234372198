<pw-account-public-page
  [pageTitle]="pageTitle"
  [descriptions]="descriptions"
  [sideImageSrc]="pageIllustration"
  [mobileImageSrc]="pageIllustrationMobile"
  pwGoogleTagManagerTrackEvents
>
  <div *ngIf="!apiErrorOccurred" class="confirm-account-reset__no-error">
    <pw-button
      label="Finish resetting my account"
      [gtmId]="gtmEventNames.FINISH_RESETTING_ACCOUNT"
      [loading]="apiRequestInProgress"
      [size]="buttonSize.Medium"
      [secondary]="false"
      (buttonClicked)="resetAccount()"
    ></pw-button>
    <pw-button
      label="Cancel"
      [gtmId]="gtmEventNames.CANCEL_RESETTING_ACCOUNT"
      [secondary]="true"
      [size]="buttonSize.Medium"
      (buttonClicked)="goToSignIn()"
    ></pw-button>
  </div>

  <div alternative *ngIf="!apiErrorOccurred">
    <span>Having problems?</span>&nbsp;<a [href]="'mailto:' + supportEmail" [attr.data-gtm-id]="gtmEventNames.FINISH_RESETTING_ACCOUNT_CONTACT_US"
      >Contact us</a
    >
  </div>

  <div *ngIf="apiErrorOccurred">
    <p i18n>{{ this.errorDescription }}</p>
    <p i18n>Please <a (click)="goToSignIn()">sign in</a> to start again.</p>
  </div>
</pw-account-public-page>
