export const COOKIE_SLIDER_CLASS = 'checkbox-toggle--slider';

export const setSlidersOnOff = () => {
  const sliderOnClass = `${COOKIE_SLIDER_CLASS}-on`;
  const sliderElements: Element[] = Array.from(document.getElementsByClassName(COOKIE_SLIDER_CLASS));
  sliderElements.forEach((sliderElement: Element) => {
    if (sliderElement.querySelector<HTMLInputElement>('input')?.checked) {
      sliderElement.classList.add(sliderOnClass);
    } else {
      sliderElement.classList.remove(sliderOnClass);
    }
  });
};
