import { Routes } from '@angular/router';
import { MsalGuard } from '@azure/msal-angular';
import { attendanceGuard, currentUserResolver } from '@pw/account/auth';
import { environment, PathwaysAccountRouteParams, PathwaysAccountRoutes } from '@pw/shared/environment';
import { MaintenanceModePageComponent } from '@pw/shared/ui';
import { BasePageComponent } from './components/base-page/base-page.component';
import { ConfirmAccountResetComponent } from './confirm-account-reset/confirm-account-reset.component';
import { AccountErrorPageComponent } from './error-page/error-page.component';
import { LocalSignOutPageComponent } from './local-sign-out/local-sign-out.component';
import { PasswordChangedComponent } from './password-changed/password-changed.component';
import { PhoneNumberChangedComponent } from './phone-number-changed/phone-number-changed.component';
import { SendPasswordChangeEmailResolver } from './resolvers/send-password-changed-email.resolver';
import { SignOutResolver } from './resolvers/sign-out.resolver';
import { SignInComponent } from './sign-in/sign-in.component';
import { SignOutPageComponent } from './sign-out/sign-out.component';
import { TermsOfUsePageComponent } from './terms-of-use-page/terms-of-use-page.component';

const maintenanceRoutes: Routes = [
  {
    path: PathwaysAccountRoutes.MAINTENANCE_MODE,
    component: MaintenanceModePageComponent,
  },
  {
    path: '**',
    redirectTo: PathwaysAccountRoutes.MAINTENANCE_MODE,
  },
];

const allRoutes: Routes = [
  {
    path: '',
    redirectTo: PathwaysAccountRoutes.HOME,
    pathMatch: 'full',
  },
  // --------------- Public paths ---------------
  {
    path: PathwaysAccountRoutes.SIGN_UP,
    loadChildren: () => import('@pw/account/sign-up').then((m) => m.PathwaysAccountSignUpModule),
  },
  {
    path: PathwaysAccountRoutes.SIGN_IN,
    component: SignInComponent,
    resolve: {
      signOut: SignOutResolver,
    },
  },
  {
    path: PathwaysAccountRoutes.SIGN_OUT,
    component: SignOutPageComponent,
    resolve: {
      signOut: SignOutResolver,
    },
  },
  {
    path: PathwaysAccountRoutes.LOCAL_SIGN_OUT,
    component: LocalSignOutPageComponent,
  },
  {
    path: `${PathwaysAccountRoutes.SIGN_OUT}/:${PathwaysAccountRouteParams.TYPE}`,
    component: SignOutPageComponent,
  },
  {
    path: PathwaysAccountRoutes.TERMS_OF_USE,
    component: TermsOfUsePageComponent,
  },
  {
    path: PathwaysAccountRoutes.PASSWORD_CHANGED,
    resolve: {
      currentUser: currentUserResolver,
    },
    children: [
      {
        path: '',
        component: PasswordChangedComponent,
        resolve: {
          sendEmail: SendPasswordChangeEmailResolver,
        },
      },
    ],
  },
  {
    path: PathwaysAccountRoutes.PHONE_NUMBER_CHANGED,
    component: PhoneNumberChangedComponent,
  },
  {
    path: PathwaysAccountRoutes.VALIDATION,
    loadChildren: () => import('@pw/account/validation').then((m) => m.PathwaysAccountValidationModule),
  },
  {
    path: PathwaysAccountRoutes.REDIRECTS,
    loadChildren: () => import('@pw/account/redirects').then((m) => m.PathwaysAccountRedirectsModule),
  },
  {
    path: `${PathwaysAccountRoutes.MULTI_STEP_CONFIRMATION}/:${PathwaysAccountRouteParams.ID}/:${PathwaysAccountRouteParams.TOKEN}`,
    component: ConfirmAccountResetComponent,
  },
  // --------------- Private Paths ---------------
  {
    path: PathwaysAccountRoutes.HOME,
    component: BasePageComponent,
    loadChildren: () => import('@pw/account/main').then((m) => m.PathwaysAccountMainModule),
    resolve: {
      currentUser: currentUserResolver,
    },
    canActivate: [MsalGuard],
  },
  {
    path: PathwaysAccountRoutes.MANAGE_ACCOUNT,
    component: BasePageComponent,
    loadChildren: () => import('@pw/account/manage').then((m) => m.PathwaysAccountManageModule),
    resolve: {
      currentUser: currentUserResolver,
    },
    canActivate: [MsalGuard],
  },
  {
    path: PathwaysAccountRoutes.ATTENDANCE_OVERVIEW,
    component: BasePageComponent,
    resolve: {
      currentUser: currentUserResolver,
    },
    canActivate: [MsalGuard, attendanceGuard],
    children: [
      {
        path: '',
        loadComponent: () => import('./attendance/attendance.component').then((m) => m.AttendanceComponent),
      },
    ],
  },
  // --------------- Errors ---------------
  {
    path: PathwaysAccountRoutes.SIGN_IN_FAIL,
    component: AccountErrorPageComponent,
  },
  {
    path: PathwaysAccountRoutes.ERROR,
    component: AccountErrorPageComponent,
  },
  {
    path: `${PathwaysAccountRoutes.ERROR}/:${PathwaysAccountRouteParams.ERROR_CODE}`,
    component: AccountErrorPageComponent,
  },
  {
    path: PathwaysAccountRoutes.NOT_FOUND,
    component: AccountErrorPageComponent,
    resolve: {
      [PathwaysAccountRouteParams.ERROR_CODE]: 'error404Resolver',
    },
  },
  {
    path: '**',
    redirectTo: PathwaysAccountRoutes.NOT_FOUND,
  },
];

export const pathwaysAccountAppRoutes = environment.MAINTENANCE_MODE ? maintenanceRoutes : allRoutes;
