import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { inject, Injectable } from '@angular/core';
import { SignOutType } from '@pw/shared/types';
import { Observable, retry, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { PathwaysAuthService } from '../services/auth.service';

@Injectable()
export class HttpErrorInterceptor implements HttpInterceptor {
  private readonly authService = inject(PathwaysAuthService);

  intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    return next.handle(request).pipe(
      retry(2),
      catchError((error) => {
        if (error?.status === 401) {
          this.authService.logout(SignOutType.EXPIRED);
        }
        return throwError(() => error);
      }),
    );
  }
}
