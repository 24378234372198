import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { PathwaysUiModule } from '@pw/shared/ui';
import { AccountFooterLinksComponent } from './components/footer-links/footer-links.component';
import { AccountPublicPageComponent } from './components/public-page/public-page.component';

@NgModule({
  declarations: [AccountFooterLinksComponent, AccountPublicPageComponent],
  exports: [AccountFooterLinksComponent, AccountPublicPageComponent],
  imports: [CommonModule, PathwaysUiModule],
})
export class PathwaysAccountUiModule {}
