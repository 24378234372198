import { Component, inject, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { BreakpointObserverService } from '@klg/shared/utils-dom';
import { User } from '@pw/account/auth';
import { StudentNotification, StudentNotificationEnum, StudentNotificationService } from '@pw/notification-data-access';
import { PathwaysAccountRoutes } from '@pw/shared/environment';
import { NotificationPanelService } from '@pw/shared/ui';
import { combineLatest, Observable, Subject, takeUntil, tap } from 'rxjs';
import { PW_NOTIFICATION_PANEL_OPEN } from '@pw/shared/types';
import { LocalStorageService } from '@klg/shared/storage';

@Component({
  templateUrl: './base-page.component.html',
  styleUrls: ['./base-page.component.scss'],
})
export class BasePageComponent implements OnInit, OnDestroy {
  readonly mainPage = PathwaysAccountRoutes.HOME;
  showSection = true;
  isManageAccount = false;
  currentUser = new User({});
  isPanelOpen$: Observable<boolean>;
  studentNotifications$: Observable<StudentNotification[]>;
  infoNotifications: StudentNotification[] = [];
  isConfirmUkAddressOpen$: Observable<boolean>;

  private readonly destroy$ = new Subject<void>();
  private readonly notificationPanelService = inject(NotificationPanelService);
  private readonly studentNotificationService = inject(StudentNotificationService);
  private readonly breakpointObserverService = inject(BreakpointObserverService);
  private readonly route = inject(ActivatedRoute);
  private readonly localStorageService = inject(LocalStorageService);

  constructor(private readonly activatedRoute: ActivatedRoute) {
    this.isPanelOpen$ = this.notificationPanelService.isPanelOpen$;
    this.studentNotifications$ = this.studentNotificationService.studentNotifications$;
    this.isConfirmUkAddressOpen$ = this.notificationPanelService.isConfirmUkAddressOpen$;
  }

  ngOnInit(): void {
    this.currentUser = this.activatedRoute.snapshot?.data?.currentUser;

    this.route.url
      .pipe(
        tap(([{ path }]) => (this.isManageAccount = PathwaysAccountRoutes.MANAGE_ACCOUNT === path)),
        takeUntil(this.destroy$),
      )
      .subscribe();

    combineLatest([this.breakpointObserverService.isMobile$, this.isConfirmUkAddressOpen$])
      .pipe(
        tap(([isMobile, isConfirmUkAddressOpen]) => (this.showSection = !(isMobile && isConfirmUkAddressOpen))),
        takeUntil(this.destroy$),
      )
      .subscribe();

    this.studentNotifications$
      .pipe(
        tap((notifications) => (this.infoNotifications = notifications.filter((notification) => notification.type === StudentNotificationEnum.info))),
        takeUntil(this.destroy$),
      )
      .subscribe();

    const isPanelOpen = this.localStorageService.getItem<boolean>(PW_NOTIFICATION_PANEL_OPEN);
    if (isPanelOpen === null) {
      localStorage.setItem(PW_NOTIFICATION_PANEL_OPEN, 'true');
    }
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }
}
