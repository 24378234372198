import { inject } from '@angular/core';
import { CanActivateFn, Router } from '@angular/router';
import { environment, PathwaysAccountRoutes } from '@pw/shared/environment';

export const attendanceGuard: CanActivateFn = () => {
  const router = inject(Router);

  if (environment.FEATURE_FLAGS.ATTENDANCE_OVERVIEW_ENABLED) {
    return true;
  }

  return router.createUrlTree(['/', PathwaysAccountRoutes.HOME]);
};
