import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { PathwaysAuthService } from '@pw/account/auth';
import { PathwaysAccountRouteParams } from '@pw/shared/environment';

@Component({
  selector: 'pw-account-error-page',
  templateUrl: './error-page.component.html',
  styleUrls: ['./error-page.component.scss'],
})
export class AccountErrorPageComponent implements OnInit {
  public errorCode = '';
  public illustrationSrc = '';
  public pageTitle = '';
  public is400Error = false;

  constructor(private readonly authService: PathwaysAuthService, private readonly activatedRoute: ActivatedRoute) {}

  ngOnInit(): void {
    this.errorCode =
      this.activatedRoute.snapshot?.paramMap.get(PathwaysAccountRouteParams.ERROR_CODE) ||
      this.activatedRoute.snapshot?.data?.[PathwaysAccountRouteParams.ERROR_CODE];
    this.is400Error = Number(this.errorCode) >= 400 && Number(this.errorCode) < 500;
    this.pageTitle = this.pageTitle || this.is400Error ? $localize`Something went wrong` : $localize`Sorry, we are having a problem with our system`;
    this.illustrationSrc = this.illustrationSrc || this.is400Error ? `assets/illustrations/client-error.png` : `assets/illustrations/server-error.png`;
  }

  public goToSignIn(): void {
    this.authService.login();
  }
}
