import { Component, inject } from '@angular/core';
import { BreakpointObserverService } from '@klg/shared/utils-dom';
import { CivicCookiesWithOptionalsService } from '@pw/shared/cookies';
import { environment } from '@pw/shared/environment';
import { Observable } from 'rxjs';

@Component({
  selector: 'pw-account-footer-links',
  templateUrl: './footer-links.component.html',
  styleUrls: ['./footer-links.component.scss'],
})
export class AccountFooterLinksComponent {
  public readonly termsOfUseUrl = `${environment.PORTAL_PATH}/terms-of-use`;
  public readonly privacyUrl = environment.PRIVACY_URL;
  isMobile$: Observable<boolean>;

  private readonly cookiesService = inject(CivicCookiesWithOptionalsService);
  private readonly breakpointObserverService = inject(BreakpointObserverService);

  constructor() {
    this.isMobile$ = this.breakpointObserverService.isMobile$;
  }

  public openCookieSettings(): void {
    this.cookiesService.open();
  }
}
